<template>
<div class="my-cdkey">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="my-cdkey-cont">
    <Table border :columns="columns" :data="dataList"></Table>
    <div class="page">
      <Page :total="total" @on-change="changePage" :current="page" show-total show-sizer show-elevator></Page>
    </div>
  </div>

  <Modal
      v-model="activeModal"
      :title="$t('codekey_my_cdkey_select_category')"
      width="1000px"
      v-if="curData"
  >
    <div class="add-container">
      <Form :label-width="100" class="company-h" >
        <FormItem :label="$t('codekey_my_cdkey_category') + ':'">
          <div>
            {{curData.firstCategory && curData.firstCategory.name}}-{{ curData.secondCategory && curData.secondCategory.name}}
          </div>
        </FormItem>
        <FormItem :label="$t('codekey_list_select_dateset') + ':'">
          <div class="category-list">
            <RadioGroup v-model="userCategory">
              <Radio :label="item.name" v-for="item in categoryList" :key="item.id"></Radio>
            </RadioGroup>
          </div>
        </FormItem>
      </Form>
    </div>

    <div slot="footer">
      <Button @click="cancelActiveModal">{{ $t('codekey_manage_cancel') }}</Button>
      <Button type="primary" @click="saveActiveModal">{{ $t('codekey_manage_save') }}</Button>
      <!--        <Button type="primary" @click="saveUser">导出激活码</Button>-->
    </div>
  </Modal>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "mycdkey",
  data(){
    return{
      columns: [
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: this.$t('codekey_detail_active_dataset'),
          render: (h, params) => {
            return h('div', {}, params.row.firstCategory.name + '-' + params.row.secondCategory.name + '-' + (params.row.category ? params.row.category.name :'不限') );
          }
        },
        {
          title: this.$t('codekey_my_data_num'),
          render: (h, params) => {
            return h('div', {}, params.row.category && params.row.category.data_count);
          }
        },
        {
          title: this.$t('codekey_detail_code'),
          render: (h, params) => {
            return h('div', [
                h('span',params.row.code),
              params.row.status == '0' ? h('span',{
                  props: {
                    type: 'primary',
                    size: 'small'
                  },
                  style: {
                    marginLeft: '5px',
                    color:'#2d8cf0',
                    cursor:'pointer'
                  },
                  on: {
                    click: () => {
                      this.doCopy(params.row);
                    }
                  }
                },this.$t('codekey_my_copy')) : ''
            ],);
          }
        },
        {
          title: this.$t('codekey_detail_active_time'),
          key: 'age',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.active_time * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('codekey_detail_remaining_time'),
          key: 'timelong',
          render: (h, params) => {
            let oDate = new Date().getTime();
            let len = params.row.active_time * 1000 - oDate + (params.row.timelong * 1000);
            let time = util.timeFormate((params.row.active_time * 1000 - oDate + (params.row.timelong * 1000))/ 1000);

            let result='';
            if(params.row.active_time != '0'){
              if(len > 0){
                let d = '';
                let h = '';
                let m = '';
                if(time.days){
                  d = time.days + this.$t('codekey_my_day')
                }
                if(time.hours){
                  h = time.hours + this.$t('codekey_my_hours')
                }
                if(time.minutes){
                  m = time.minutes + this.$t('codekey_my_minute');
                }
                result = d + h + m + time.seconds + this.$t('codekey_my_second')
              }else{
                result = 0;
              }
            }else{
              result = this.timelongsObj[params.row.timelong];
            }
            return h('div', {}, result);
          }
        },
        {
          title: this.$t('codekey_detail_status'),
          key: 'age',
          render: (h, params) => {
            return h('div', {}, this.statusObj[params.row.status]);
          }
        },
        {
          title: this.$t('codekey_detail_op'),
          key: 'action',
          width: 100,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              params.row.status == '1' ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.goTrain();
                  }
                }
              },this.$t('codekey_my_go_train')) : params.row.status == '0' ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    if(params.row.category && params.row.category.id){
                      this.activeCode(params.row.category.id);
                    }else{
                      this.activeModal = true;
                      this.userCategory = '';
                      this.getCategory();
                    }
                  }
                }
              }, this.$t('codekey_my_active')) : ''

            ]);
          }
        },
      ],
      dataList: [],
      total:0,
      page:1,
      pageSize:10,
      statusObj:{},
      timelongsObj:{},
      categoryList:[],
      userCategory:'',
      activeModal:false,
      curData:{},
    }
  },
  mounted(){
    this.getList();
  },
  watch:{
    '$store.state.user.userInfo.id':function(newV,oldV){
      if(newV){
        this.getList();
      }
    }
  },
  methods:{
    getList(){  //获取列表数据
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        active_user_id:this.$store.state.user.userInfo.id,
      };
      this.api.codekey.cdkeyCodeList(params).then((res)=>{
        this.dataList = res.list;
        this.statusObj = res.statuses;
        this.timelongsObj = res.timelongs;
        this.total = Number(res.count);
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getList();
    },
    goTrain(){  //前往练习
      this.$router.push({
        path:'/trainingcamp/trainDetail',
        query:{
          id:this.curData.category_id,
          dataSort:'1',   //0每日一练1全部
        }
      })
    },
    activeCode(categoryId){ //激活

      let params = {
        code:this.curData.code,
        is_sure:'1'
      };
      if(categoryId){
        params.category_id = categoryId;
      }
      this.api.codekey.cdkeyActive(params).then((res)=>{
        this.$Message.success(this.$t('codekey_my_active_success'));
        this.activeModal = false;
        this.getList();
      })
    },
    doCopy(data){
      this.$copyText(data.code).then( (e)=>{
        this.$Message.success(this.$t('codekey_my_copy_success'));
      }, (e)=> {
        this.$Message.success(this.$t('codekey_my_copy_fail'));
      })
    },
    getCategory(){
      let params = {
        category_id : this.curData.first_category_id,
        category_second_id:this.curData.second_category_id
      };

      this.api.dataset.categoryForm(params).then((res)=>{
        this.categoryList =[
          ...res.categoryThirdList
        ]
      })
    },
    cancelActiveModal(){
      this.activeModal = false;
    },
    saveActiveModal(){
      if(!this.userCategory){
        this.$Message.warning(this.$t('codekey_my_cdkey_select_category_message'));
        return;
      }
      let categoryId = this.categoryList.filter((item)=>{
        return item.name == this.userCategory;
      })[0].id;

      this.activeCode(categoryId);
    }
  }
}
</script>

<style scoped lang="scss">
.my-cdkey{
  margin: 20px;

  .my-cdkey-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.category-list{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  >div{
    margin-right: 10px;
    padding: 0px 10px;
    cursor: pointer;
  }
  >div.active{
    background-color: #2d8cf0;
    color:#FFFFFF;
    border-radius: 4px;
  }
}
</style>
